import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import {
  checkVal,
  formatPrice,
  makeTagText,
} from "../../../../../utils/constants";

function ShowAd({ item }) {
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const getPrice = (currency, data) => {
    if (!checkVal(data?.askPrice) && !checkVal(data?.dollorAskPrice)) {
      return "";
    }
    const name = data?.adTypeName?.en || data?.adType?.name?.en;
    if (name === "Sell") {
      return formatPrice(lang2, data?.askPrice, data?.dollorAskPrice);
    } else {
      return formatPrice(lang2, data?.askPrice, data?.dollorAskPrice).concat(
        "/" + t("Day")
      );
    }
  };
  const carImg =
    item?.uploadsInOrder?.uploads?.[0]?.thumbnail ||
    item?.uploadsInOrder?.uploads?.[0]?.s3URL ||
    item?.uploads?.[0]?.s3URL ||
    "/assets/images/dash_img/simple-car.png";
  return (
    <>
      <span
        className="position-absolute"
        style={{
          [lang2 === "en" ? "right" : "left"]: "5px",
          top: "5px",
        }}
      >
        <p
          className="text-light rounded-pill"
          style={{
            backgroundColor: "#4c698f",
            fontSize: "13px",
            paddingInline: "8px",
          }}
        >
          {item?.adType?.name?.[lang2]}
        </p>
      </span>
      <img
        src={carImg}
        height={70}
        width={80}
        style={{ objectFit: "cover" }}
        className="rounded-2 border"
      />
      <div className="d-flex flex-column ">
        <span style={{ fontSize: "18", fontWeight: 600, color: "#2b2b2b" }}>
          {getPrice("IQD", item) || t("Contact seller")}
        </span>
        <span
          className="fw-semibold"
          style={{
            fontSize: "14px",
            color: "#5b5b5b",
          }}
        >
          {t(item?.properties?.basicDetails?.make?.en)}{" "}
          {t(item?.properties?.basicDetails?.model?.en)}
        </span>
        <span
          style={{
            fontSize: "12px",
            color: "grey",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {makeTagText(item)}
        </span>
      </div>
    </>
  );
}

export default ShowAd;
