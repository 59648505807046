import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ElectricBanner from "./ElectricBanner";
import ElectricCarsType from "./ElectricCarsType";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import PopularModal from "../../Tools/PopularModal";
import ComingSoon from "../../ComingSoon";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import staticFiltersData from "../../../data/withoutVin.json";
import { getEntityTypes } from "../../../Reducer/api";
import SearchedSection from "../Home/SearchedSection";
import RecentlyCarView from "../../Tools/RecentCarView";
import { BenefitsSection, SliderSection, WhyElectric } from "./ElectricCarsContent";
import Follow from "../Home/Follow";
import PlaceSection from "../Home/PlaceSection";
export default function ElectricCars({ page }) {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);

  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };
  useEffect(() => {
    getCarTypes();
  }, []);

  const filters = {
    fuel: staticFiltersData.data.fuel
      .filter((v) => v?.type === "electric")
      .map((v) => v?.name?.en),
  };

  return (
    <>
      {/* <ComingSoon /> */}
      <ElectricBanner
        page={page}
        carTypes={carTypes}
        filters={filters}
        rtl={rtl}
      />
      <PopularModal
        rtl={rtl}
        filters={filters}
        title={"Electric car deals"}
        buttonTitle={"View all electric car deals"}
      />
      <WhyElectric />
      {/* <StepsToBuyNew /> */}
      {/* <BenefitsSection /> */}
      <PlaceSection page={page} />
      <BrandSection
        brandTitle={t("Popular electric car brands")}
        filters={filters}
      />
      <SliderSection />
      <SearchedSection
        rtl={rtl}
        page={page}
        carTypes={carTypes}
        setCarTypes={setCarTypes}
        selectedCarType={selectedCarType}
        setSelectedCarType={setSelectedCarType}
        title={t("Find the right type of electric cars")}
        filters={filters}
      />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed car deals"}
      />
      <Follow />
      <JsonLDSchemaHandler data={ldSchemas?.electricCars} />
    </>
  );
}
