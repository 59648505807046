import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
function ContentSlider({ content, renderChild }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const len = content?.length || 2;
  const [currentIndex, setCurrentIndex] = useState(0);
  const moveSection = (dir) => {
    console.log("moved", dir);
    if (lang !== "en") dir = dir === "next" ? "prev" : "next";
    // if (dir === "next" && currentIndex === content.length - 1) return;
    // if (dir === "prev" && currentIndex === 0) return;

    if (dir === "next") {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % len);
    }
    if (dir === "prev") {
      if (currentIndex === 0) setCurrentIndex(content.length - 1);
      else setCurrentIndex((currentIndex) => (currentIndex - 1) % len);
    }
  };
  const intervalRef = useRef(null);

  // useEffect(() => {
  //   startAutoplay();
  //   return () => {
  //     stopAutoplay();
  //   };
  // }, []);

  const startAutoplay = () => {
    intervalRef.current = setInterval(() => {
      moveSection("next");
    }, 5000);
  };

  const stopAutoplay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        onFocus={(e) => stopAutoplay()}
        onBlur={(e) => startAutoplay()}
      >
        <div
          className={`owl-nav d-flex justify-content-between w-100 bg-transparent gap-2 arrow-button ${
            lang !== "en" ? "flex-row-reverse" : ""
          }`}
          style={{ marginBottom: "-50px", zIndex: 9 }}
        >
          <div className="bg-light rounded-1 d-flex justify-content-between align-items-center">
            <button
              className="owl-prev position-static"
              style={{ margin: "unset !important" }}
              onClick={() => moveSection("prev")}
            ></button>
          </div>
          <div className="bg-light rounded-1 d-flex justify-content-between align-items-center">
            <button
              className="owl-next position-static"
              style={{ margin: "unset !important" }}
              onClick={() => moveSection("next")}
            ></button>
          </div>
        </div>
      </div>
      <div
        className="slider-container"
        onFocus={(e) => stopAutoplay()}
        onBlur={(e) => startAutoplay()}
      >
        <div
          className="slider"
          style={{
            transform: `translateX(${lang === "en" ? "-" : ""}${
              currentIndex * 100
            }%)`,
          }}
        >
          {content.map((item, index) => (
            <>
              <section className="p-0" style={{ minWidth: "100%" }} key={index}>
                <div
                  className="slider-section-title "
                  style={{ height: "15%" }}
                >
                  <h4 className=" text-center white-text-below-sm hed mb-0">
                    {item?.title || ""}
                  </h4>
                </div>
                {/*{item?.description && (
                    <p
                      style={{ minHeight: "80px" }}
                      className=" desc text-center mb-0 px-4"
                    >
                      {item?.description || ""}
                    </p>
                  )} */}
                <div
                  className=" row m-0 gap-2 flex-wrap"
                  style={{
                    height: `85%`,
                    position: "relative",
                    zIndex: 999,
                  }}
                >
                  {renderChild(item)}
                </div>
              </section>
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default ContentSlider;
