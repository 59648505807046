import React from "react";
import {
  checkVal,
  findUploads,
  formatPrice,
  isImageOrVideo,
  makeTagText,
  setAdsArray,
  setCourierFilters,
} from "../../../utils/constants";
import { t } from "i18next";
import { paths } from "../../../utils/paths";
import { useNavigate } from "react-router-dom";

function MyAdCard({ key, lang, item, openModal, allAdIds }) {
  const navigate = useNavigate();

  const tagText = makeTagText(item, lang);
  const firstUpload = findUploads(item)?.[0];
  const firstImg =
    firstUpload?.thumbnail ||
    firstUpload?.s3URL ||
    "/assets/images/notFound.png";

  const getPrice = (currency, data) => {
    if (!checkVal(data?.askPrice) && !checkVal(data?.dollorAskPrice)) {
      return "";
    }
    if (data?.adTypeName?.en === "Sell") {
      return formatPrice(lang, data?.askPrice, data?.dollorAskPrice);
    } else {
      return formatPrice(lang, data?.askPrice, data?.dollorAskPrice).concat(
        "/" + t("Day")
      );
    }
  };
  const goToCar = (id, type, sold) => {
    // setCourierFilters({myAds: true}, "myAds")
    if (allAdIds?.length > 0) {
      setAdsArray(allAdIds);
    } else {
      setAdsArray([]);
    }
    if (type === "Sell") {
      navigate(`${paths.singleCar}?adId=${id}${sold ? "&sold=true" : ""}`);
    } else {
      navigate(`${paths.singleRental}?adId=${id}${sold ? "&sold=true" : ""}`);
    }
  };
  const getStatusBackgroundColor = (item) => {
    let name = "";
    if (!item?.isAdCompleted) {
      name = "";
    } else if (item?.uploads?.length == 0) {
      name = "";
    } else if (!item?.isApproved) {
      name = "";
    } else if (!item?.display) {
      if (item?.statusName?.en === "Sold") {
        name = "Sold";
      } else {
        name = "";
      }
    } else {
      name = item?.statusName?.en;
    }
    switch (name) {
      case "Sold":
        return "#2da84f";
      case "Active":
        return "#f0ad4e";
      default:
        return "#e44e4e";
    }
  };
  const getStatus = (item) => {
    let name = "";
    if (!item?.isAdCompleted) {
      name = "Payment Pending";
    } else if (item?.uploads?.length == 0) {
      name = t("Upload Media");
    } else if (!item?.isApproved) {
      if (item?.approveStatus === "pending") {
        name = t("ad pending approval");
      } else if (item?.approveStatus === "rejected") {
        name = t("ad rejected");
      }
    } else if (!item?.display) {
      if (item?.statusName?.en === "Sold") {
        name = t("Sold");
      } else {
        name = t("ad inactive");
      }
    } else {
      name = item?.statusName?.[lang];
    }
    return name;
  };
  const handleRenewPlan = (item) => {
    let page = "";
    if (item?.adTypeName?.en === "Sell") page = "SellYourCar";
    if (item?.adTypeName?.en === "Rent") page = "RentYourCar";

    navigate(paths.renew, { state: { page: page, item: item, isRenew: true } });
  };

  return (
    <div class="col-lg-4 col-md-6 col-xs-12 mb-2" key={key}>
      <div class="item">
        <div
          class="set"
          style={{
            position: "relative",
            overflow: "hidden",
            padding: " 0px",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: lang === "en" ? "0px" : "unset",
              left: lang === "en" ? "unset" : "0px",
              top: "0px",
              fontSize: "11px",
              padding: "3px 10px 3px 10px",
              backgroundColor: getStatusBackgroundColor(item),
              color: "white",
              borderRadius:
                lang === "en" ? "0px 8px 0px 8px" : "8px 0px 8px 0px",
            }}
          >
            {getStatus(item)}
          </div>
          <div
            class="img"
            style={{ cursor: "pointer" }}
            onClick={() =>
              goToCar(item?._id, item?.adTypeName?.["en"], item?.sold)
            }
          >
            {isImageOrVideo(item?.uploads.map((v) => v.s3URL)?.[0]) ===
            "video" ? (
              <video
                class="w-100"
                height={180}
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  marginBottom: "3px",
                }}
              >
                <source src={item?.uploads.map((v) => v.s3URL)?.[0]} />
              </video>
            ) : (
              <img
                src={firstImg}
                alt="blog1"
                class="w-100"
                height={180}
                style={{
                  borderRadius: "8px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />
            )}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              goToCar(item?._id, item?.adTypeName?.["en"], item?.sold)
            }
          >
            <span className="d-flex justify-content-between">
              <h5
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                {t(item?.properties?.basicDetails?.model?.[lang])}
              </h5>

              <div className={``}>
                <div
                  className="float-end1 bg-success text-light fw-semibold text-center"
                  style={{
                    borderRadius: "3px",
                    padding:
                      lang === "en" ? "1px 0px 1px 0px" : "1px 0px 3px 0px",
                    fontSize: "13px",
                    width: "40px",
                  }}
                >
                  {item?.adTypeName?.[lang]}
                </div>
                {/* <div
                          className="float-end1 bg-warning text-light fw-semibold text-center"
                          style={{
                            borderRadius: "3px",
                            padding:
                              lang === "en"
                                ? "1px 0px 1px 0px"
                                : "1px 0px 3px 0px",
                            fontSize: "13px",
                            width: "40px",
                          }}
                        >
                          {item?.adTypeName?.[lang]}
                        </div> */}
              </div>
            </span>
            <p
              className="fs-6"
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                height: "45px",
              }}
            >
              {tagText}
            </p>
            <p>
              {item?.properties?.specifications?.fuelType?.[lang]
                ? t(item?.properties?.specifications?.fuelType?.[lang])
                : "--"}
            </p>
            <h6>{getPrice(item?.currency, item) || t('Contact seller')} </h6>
          </div>

          {item?.sold ? (
            <div
              className="w-100 rounded-1 text-light d-flex align-items-center justify-content-center"
              style={{
                height: "43px",
                marginTop: "15px",
                backgroundColor: "#2DA84F",
              }}
            >
              {t("Sold")}
            </div>
          ) : item?.statusName?.en == "Expired" ? (
            <>
              <div class="edit_div">
                <a
                  className="edit"
                  onClick={() => navigate(paths.editCar, { state: item })}
                >
                  {t("Edit Vehicle")}
                </a>
                <a class="trash" onClick={() => openModal("remove", item)}>
                  <img src="../assets/images/trash.png" alt="img" />
                </a>
              </div>
              <a
                onClick={() => handleRenewPlan(item)}
                class="markbtn border text-light d-flex align-items-center justify-content-center"
                style={{
                  height: "43px",
                  marginTop: "15px",
                  backgroundColor: "#2DA84F",
                }}
              >
                {t("RenewOrUpgrade")}
              </a>
            </>
          ) : (
            <>
              <div class="edit_div">
                <a
                  className="edit"
                  onClick={() => navigate(paths.editCar, { state: item })}
                >
                  {t("Edit Vehicle")}
                </a>
                <a class="trash" onClick={() => openModal("remove", item)}>
                  <img src="../assets/images/trash.png" alt="img" />
                </a>
              </div>
              <a onClick={() => openModal("sold", item)} class="markbtn">
                {t("Mark as Sold")}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyAdCard;
