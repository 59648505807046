import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  faq,
  faq_newcars,
  faq_rent,
  faq_Sell_your_car,
  faq_used_cars,
} from "../../../data/faqContent";
function FaqPage() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const rtl = lang !== "en";

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };
  const getFAQ = () => {
    switch (location?.state?.type) {
      case "home":
        return faq;
      case "rent":
        return faq_rent;
      case "newcars":
        return faq_newcars;
      case "sell_your_car":
        return faq_Sell_your_car;
      case "usedcars":
        return faq_used_cars;
      default:
        return [];
    }
  };
  return (
    <section class="recent_articles min-vh-100">
      <div className="container faq">
        <h6
          class="back mx-2"
          style={{ direction: i18n.language === "English" ? "ltr" : "rtl" }}
        >
          <NavLink to={-1}>
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </NavLink>
        </h6>
        <h1 class="hed mb-4">{t("Frequently asked questions")}</h1>
        <Accordion
          open={accordion}
          toggle={toggleAccordion}
          className=" rounded-2 py-1"
        >
          {getFAQ()?.map((item, index) => {
            return (
              <AccordionItem className="rounded-2 m-2 outline-none">
                <AccordionHeader
                  targetId={index + 1}
                  className="d-flex justify-content-between"
                  style={{ width: "100%", borderRadius: "20px" }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      textAlign: rtl ? "right" : "left",
                    }}
                  >
                    {item?.que?.[lang]}
                  </span>
                </AccordionHeader>
                <AccordionBody accordionId={index + 1}>
                  <div style={{ fontSize: "16px" }}>{item?.ans?.[lang]}</div>
                </AccordionBody>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </section>
  );
}

export default FaqPage;
