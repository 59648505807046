import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../SelctOptions";
import ListItem from "../ListItem";
import FilterModel from "../FilterModel";
import $ from "jquery";
import { NavLink, useLocation } from "react-router-dom";
import KeywordInput from "../Keyword";
import {
  formatPrice,
  getAllFiltersData,
  getAllFiltersData1,
  getSelectedFilters,
  numWithZero,
  numberWithCommas,
} from "../../../utils/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import SelectMultiple from "../SelectMultiple";
import Button from "../../Tools/Button";
import { hideSpec } from "../Forms/AddCar/addFormUtils";
import { getAdFiltersList1 } from "../../../Reducer/api";
import { useDidMountEffect } from "../../../utils/hooks";

export default function FilterSideBar({
  dataset,
  // data,
  handleClickevent,
  allFilters,
  setAllFilters,
  page,
  models,
  resetFilters,
  ownerId,
  selectedContent,
  // getSelectedFilters
  filtersRef,
  setCurrentPage,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const location = useLocation();
  const any = t("Any");
  const [keywords, setKeywords] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const skipModel = ["", null, undefined];
  const handleClick = (id, type) => {
    if (filtersLoading) return;

    // if (type === "model" && skipModel.includes(allFilters.make)) return;

    if ($(`#air${id}`).hasClass("show")) {
      $(`.dropdown-menu`).removeClass("show");
      $(".dropdown").removeClass("show");
      setSelected(null);
    } else {
      $(`.dropdown-menu`).removeClass("show");
      $(".dropdown").removeClass("show");
      $(`#maq${id}`).addClass("show");
      $(`#air${id}`).addClass("show");
      setSelected(id);
    }
  };

  const handleAddKeyword = (newKeyword) => {
    setKeywords([...keywords, newKeyword]);
  };

  const removetext = (key) => {
    setKeywords((keywords) => keywords.filter((item, index) => key !== index));
  };
  const [data, setData] = useState(null);
  useDidMountEffect(() => {
    // setFiltersLoading(true);
    // getAllFiltersData(
    //   selectedContent,
    //   ownerId,
    //   allFilters,
    //   getSelectedFilters(
    //     selectedFilters,
    //     setSelectedFilters,
    //     allFilters,
    //     filtersRef,
    //     setCurrentPage
    //   )
    // ).then((res) => {
    //   setData(res);
    //   setFiltersLoading(false);
    // });
    const keys = [
      "cities",
      "condition",
      "paint",
      "colour",
      "regional",
      "gearbox",
      "fuel",
      "bodyType",
      "plateTypes",
      // "transmission",
      "makes",
      "yearsList",
    ];
    const selected = getSelectedFilters(
      selectedFilters,
      setSelectedFilters,
      allFilters,
      filtersRef
    );
    keys.forEach(async (key) => {
      let response = await getAdFiltersList1(
        page,
        "",
        allFilters,
        selected,
        key
      );
      console.log(response)
      setData((data) => ({ ...data, ...getAllFiltersData1(response) }));
    });
  }, [selectedContent, allFilters]);

  const skip = ["", NaN, null, undefined, "Any", any];
  const showValueOnButton = (name) => {
    let text = "";
    switch (name) {
      case "make":
        text = allFilters?.make ? t(allFilters?.make) : t("Any");
        break;
      case "model":
        text = allFilters?.model ? t(allFilters?.model) : t("Any");
        break;
      case "body":
        text =
          allFilters?.condition?.length == 0
            ? t("Any")
            : allFilters?.condition?.length > 1
            ? t("Multi")
            : t(allFilters?.condition?.[0]);
        break;
      case "paint":
        text =
          allFilters?.paint?.length == 0
            ? t("Any")
            : allFilters?.paint?.length > 1
            ? t("Multi")
            : t(allFilters?.paint?.[0]);
        break;
      case "colour":
        text =
          allFilters?.colour?.length == 0
            ? t("Any")
            : allFilters?.colour?.length > 1
            ? t("Multi")
            : t(allFilters?.colour?.[0]);
        break;
      case "regional_space":
        text =
          allFilters?.regional?.length == 0
            ? t("Any")
            : allFilters?.regional?.length > 1
            ? t("Multi")
            : t(allFilters?.regional?.[0]);
        break;
      case "gearbox":
        text =
          allFilters?.gearbox?.length == 0
            ? t("Any")
            : allFilters?.gearbox?.length > 1
            ? t("Multi")
            : t(allFilters?.gearbox?.[0]);
        break;
      case "fuel":
        text =
          allFilters?.fuel?.length == 0
            ? t("Any")
            : allFilters?.fuel?.length > 1
            ? t("Multi")
            : t(allFilters?.fuel?.[0]);
        break;
      case "body_type":
        text =
          allFilters?.bodyType?.length == 0
            ? t("Any")
            : allFilters?.bodyType?.length > 1
            ? t("Multi")
            : t(allFilters?.bodyType?.[0]);
        break;
      case "min_max_price":
        if (
          skip.includes(allFilters.price.min) &&
          skip.includes(allFilters.price.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.price.min)
            ? numberWithCommas(allFilters.price.min)
            : t("Any");
          let max = !skip.includes(allFilters.price.max)
            ? numberWithCommas(allFilters.price.max)
            : t("Any");
          text =
            lang === "en"
              ? `${t("IQD")} ${min} - ${t("IQD")} ${max}`
              : `${min} ${t("IQD")} - ${max} ${t("IQD")}`;
          console.log(text, min, max);
        }
        break;
      case "min_max_year":
        if (allFilters.brandNew) {
          text = t("Brand new");
          break;
        }
        if (
          skip.includes(allFilters.year.min) &&
          skip.includes(allFilters.year.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.year.min)
            ? allFilters.year.min
            : t("Any");
          let max = !skip.includes(allFilters.year.max)
            ? allFilters.year.max == 9999
              ? t("Brand new")
              : allFilters.year.max
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "min_max_kilometer":
        if (
          skip.includes(allFilters.km.min) &&
          skip.includes(allFilters.km.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.km.min)
            ? numberWithCommas(allFilters.km.min)
            : t("Any");
          let max = !skip.includes(allFilters.km.max)
            ? numberWithCommas(allFilters.km.max)
            : t("Any");
          text = max + " - " + min;
        }
        break;
      case "min_max_engine":
        if (
          skip.includes(allFilters.size.min) &&
          skip.includes(allFilters.size.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.size.min)
            ? t(numWithZero(allFilters.size.min) + " " + t("Litre"))
            : t("Any");
          let max = !skip.includes(allFilters.size.max)
            ? t(numWithZero(allFilters.size.max) + " " + t("Litre"))
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "min_max_battery":
        if (
          skip.includes(allFilters.batterySize.min) &&
          skip.includes(allFilters.batterySize.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.batterySize.min)
            ? t(allFilters.batterySize.min + " " + t("kWh"))
            : t("Any");
          let max = !skip.includes(allFilters.batterySize.max)
            ? t(allFilters.batterySize.max + " " + t("kWh"))
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "min_max_engine_power":
        if (
          skip.includes(allFilters.power.min) &&
          skip.includes(allFilters.power.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.power.min)
            ? t(allFilters.power.min + " bhb")
            : t("Any");
          let max = !skip.includes(allFilters.power.max)
            ? t(allFilters.power.max + " bhb")
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "cylinders":
        if (
          skip.includes(allFilters.cylinders.min) &&
          skip.includes(allFilters.cylinders.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.cylinders.min)
            ? allFilters.cylinders.min
            : t("Any");
          let max = !skip.includes(allFilters.cylinders.max)
            ? allFilters.cylinders.max
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "doors":
        if (
          skip.includes(allFilters.doors.min) &&
          skip.includes(allFilters.doors.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.doors.min)
            ? allFilters.doors.min
            : t("Any");
          let max = !skip.includes(allFilters.doors.max)
            ? allFilters.doors.max
            : t("Any");
          text = min + " - " + max;
        }
        break;
      case "seats":
        if (
          skip.includes(allFilters.seats.min) &&
          skip.includes(allFilters.seats.max)
        ) {
          text = t("Any");
        } else {
          let min = !skip.includes(allFilters.seats.min)
            ? allFilters.seats.min
            : t("Any");
          let max = !skip.includes(allFilters.seats.max)
            ? allFilters.seats.max
            : t("Any");
          text = min + " - " + max;
        }
        break;
    }

    // if (text === t('Any').concat(" - ").concat(t("Any"))) return t("Any")
    // if (text === (" - ").concat(t("Any"))) return ""
    // if (text === t('Any').concat(" - ")) return ""

    return text?.length <= 35 ? text : text?.substring(0, 20).concat("...");
  };

  const filterSkip = [t("Any"), "$0", t("Brand new")];

  const showFilteredValues = (type, fullData, value, minOrMax) => {
    // console.log(value)
    if (!value) return fullData;
    if (filterSkip.includes(value)) return fullData;

    if (minOrMax === "min") {
      return fullData?.filter((v) => v.value <= parseFloat(value));
    }
    if (minOrMax === "max") {
      return fullData?.filter((v) => v.value >= parseFloat(value));
    }

    return fullData;
  };

  //utility func, if any filter needs to be conditionally rendered
  const canRender = (item) => {
    const col = item?.backend_column;
    switch (col) {
      case "cylinders":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.cylinder.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      case "min_max_engine":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.engineSize.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      case "min_max_battery":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.batterySize.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  return (
    <div class="sidebar">
      <div class="set" style={{ padding: "unset", overflow: "" }}>
        <h4>
          {t("Basics filters")}
          <span
            class="close_ic"
            style={{ cursor: "pointer" }}
            onClick={() => $(".sidebar").removeClass("main")}
          >
            <img src="../assets/images/close_img.png" alt="icon" /> {t("Close")}
          </span>
        </h4>

        {!location.pathname.includes("Dealer") && (
          <div class="row mb-3" style={{ padding: "15px" }}>
            <div class="col-12">
              <label>{t("Cities")}</label>
              <SelectMultiple
                selectedOption={
                  allFilters?.city !== t("National") ? allFilters?.city : null
                }
                placeholder={t("National")}
                onChange={(e) =>
                  setAllFilters({ ...allFilters, city: e?.value })
                }
                values={data?.cities}
                showDropdownIcon
              />
            </div>
            {/* <div class="col-12">
            <label>{t("Distance")}</label>
            <SelectOptions
              style={"form-control"}
              selectedOption={""}
              name={"distance"}
              values={data.distance}
              onChange={handleChange}
            />
          </div> */}
          </div>
        )}
        <ul>
          {dataset.drop_down_filter && dataset.drop_down_filter.length > 0
            ? dataset.drop_down_filter.map((item, index) => {
                if (!canRender(item)) return;
                return (
                  <ListItem
                    style={"nav-item dropdown"}
                    id={`air${index}`}
                    innerhtmlType={"html"}
                    inlineStyle={{
                      // condition to remove margintop from make filter since city is removed filter from dealer car view
                      borderTop:
                        item?.backend_column === "make" &&
                        location.pathname.includes("Dealer")
                          ? "none"
                          : "",
                    }}
                    value={
                      <>
                        <a
                          class={`nav-link 
                            ${
                              // item?.backend_column === "model" &&
                              // skipModel.includes(allFilters.make)
                              //   ? "bg-light text-secondary"
                              //   : ""
                              ""
                            } 
                          ${filtersLoading ? "bg-light" : ""} `}
                          onClick={() =>
                            handleClick(index, item?.backend_column)
                          }
                          aria-expanded="false"
                          style={{
                            cursor:
                              // (item?.backend_column === "model" &&
                              //   skipModel.includes(allFilters.make))
                              //   ||
                              filtersLoading ? "not-allowed" : "",
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              {t(item.name)}{" "}
                              {/* <i class="fa-solid fa-angle-left" ></i> */}
                              <Icon
                                icon="mingcute:right-line"
                                fontSize={20}
                                rotate={
                                  selected == index
                                    ? lang === "en"
                                      ? 1
                                      : 3
                                    : 0
                                }
                              />
                            </div>
                            <span
                              class={`badge m${
                                lang === "en" ? "e" : "s"
                              }-4 rounded-5`}
                              style={{
                                padding: "5px",
                                fontSize: "13px",
                                backgroundColor: "none",
                                color:
                                  // item?.backend_column === "model" &&
                                  // skipModel.includes(allFilters.make)
                                  filtersLoading ? "gray" : "#4C7EE8",
                                // borderRadius: "15px",
                              }}
                            >
                              {showValueOnButton(item?.backend_column)}
                            </span>
                          </div>
                        </a>
                        <FilterModel
                          id={`maq${index}`}
                          handleClick={handleClickevent}
                          title={t(item?.name)}
                          data={data?.[item?.backend_column]}
                          type={item?.backend_column}
                          dataType={item?.data_type}
                          allFilters={allFilters}
                          setAllFilters={setAllFilters}
                          showFilteredValues={showFilteredValues}
                          setSelected={setSelected}
                          page={page}
                          models={models}
                        />
                      </>
                    }
                  />
                );
              })
            : ""}
        </ul>
        {/* <KeywordInput onAddKeyword={handleAddKeyword} /> */}
        {/* <ul className="add_drop">
          {keywords.map((keyword, index) => (
            <li key={index}>
              {keyword}{" "}
              <span onClick={() => removetext(index)}>
                <i class="fa-solid fa-xmark"></i>
              </span>
            </li>
          ))}
        </ul>
        <p style={{ paddingInline: '15px' }}>
          <a href="#">
            <img src="assets/images/str.png" lang="icon" /> {t("Save Search")}
          </a>
        </p>
        <p style={{ paddingInline: '15px' }}>
          <NavLink to={paths.saved}>{t("View your saved searches")}</NavLink>
        </p> */}
        <Button
          inlineStyle={{
            color: " #343434",
            fontWeight: "500",
            textDecoration: "none",
            fontSize: "15px",
            border: "none",
            background: "#fff",
            padding: 0,
          }}
          style="bnnr-btn"
          onClick={(e) => {
            e.preventDefault();
            resetFilters();
          }}
          title={
            <>
              <img
                height={26}
                className="mx-2 my-3"
                src={"../assets/images/reset_settings.png"}
              />
              {t("Reset settings")}
            </>
          }
        />
      </div>
    </div>
  );
}
