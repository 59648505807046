import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { title } from "../../../../../data/content";
import {
  clearStatus,
  myprofilefetch,
} from "../../../../../Reducer/myprofileSlice";
import EditWorkingHour from "./EditWorkingHour";
import Profile from "./Profile";
import Security from "./Security";
import WorkingHour from "./WorkingHour";
import KycDocuments from "./DealershipDocuments";
import UploadCover from "./UploadCover";
import { NavLink, useLocation } from "react-router-dom";
import { paths } from "../../../../../utils/paths";

export default function MyProfile({ page }) {
  const data = useSelector((state) => state.myprofile.profilearr);
  const success = useSelector((state) => state.myprofile.success);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    dispatch(myprofilefetch({ setLaoding: setLoading }));
  }, [dispatch]);

  const fullTypes = ["dealer", "brand"];

  useEffect(() => {
    console.log(success);
    if (success) {
      setLoading(false);
      clearStatus();
    }
  }, [success]);
  console.log("userType, userType");

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div class="my_profile">
      <Profile data={data ? data : []} page={userType} />
      <Security data={data ? data : []} />
      {fullTypes.includes(userType) ? (
        <KycDocuments data={data ? data : []} />
      ) : (
        ""
      )}
      {fullTypes.includes(userType) ? (
        <WorkingHour data={data ? data : []} />
      ) : (
        ""
      )}
      {fullTypes.includes(userType) ? (
        <UploadCover data={data ? data : []} />
      ) : (
        ""
      )}
      <div>
        <NavLink
          className="atclose"
          to={
            location.pathname.includes("MyAccount")
              ? paths.myCloseAccount
              : paths.closeAccount
          }
        >
          <h3 class="securityh3 d-flex align-items-center gap-3">
            {" "}
            <img src="../assets/images/close_img.png" height={22} className="mb-1"/>
            {t("Close my Account")}
          </h3>
        </NavLink>
      </div>
    </div>
  );
}
